.section-title {
  font-size: 2rem;
  color: #333;
  /* border-bottom: 2px solid #ff6347; */
  /* padding-bottom: 10px;
  margin-bottom: 20px; */
  text-align: left;
  font-family: "GanhTypeRegular", sans-serif;

}

.text {
  font-family: "GanhTypeRegular", sans-serif;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}

.ingredient-image {
  width: 75%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.roll-image {
  width: 75%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  
}

