/* Header.css */

.navbar-brand img {
  height: 60px; /* Adjust logo height */
  margin-right: 10px; /* Add margin to the right of the logo */
}

.navbar-brand {
  font-size: 1.5rem; /* Increase font size of brand text */
  font-family: "GanhTypeRegular", sans-serif;
}

.navbar-nav .nav-link {
  color: #E6D5CA; /* Set link text color */
  font-weight: bold; /* Make link text bold */
  margin-right: 15px; /* Add margin between links */
  font-family: "GanhTypeRegular", sans-serif;
}

.navbar-nav .nav-link:hover {
  text-decoration: underline;
  font-weight: bold;
}
/* .nav-menu li hr {
  border-top: 2px solid #000;
} */

