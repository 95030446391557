
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #E6D5CA;
  font-family: "GanhTypeRegular", sans-serif;

}

/* Header styles */
.menu-header {
  color: #211F4C;
  font-size: 3.5em;
  margin-top: 20px;
  text-transform: uppercase;
}

/* .menu h4 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #860b0b;
} */

.menu-section {
  margin-bottom: 30px;
}

/* Flexbox for layout */
.menu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.menu-section {
  flex: 1 1 calc(50% - 10px);
  padding: 20px;
  margin: 10px 0;
}

.menu-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
  color: #211F4C;
  text-transform: uppercase;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px dashed #ccc;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item .item-name {
  font-size: 1rem;
  font-weight: 500;
}

.menu-item .item-price {
  font-size: 1rem;
  font-weight: 500;
  color: #211F4C;
}

/* Combo section styles */


/* Responsive styles */
@media (max-width: 768px) {
  .menu-section {
    flex: 1 1 100%;
  }
}

