.hero {
  height: 80vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "GanhTypeRegular", sans-serif;
}

.hero-text {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 2rem;
}

.hero-text h1 {
  font-size: 2.7rem;
  margin-bottom: 1.5rem;
}

.hero-text p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.btn-link {
  text-decoration: none;
}
.menu-btn,.booking-btn {
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background: #3d3a4e;
  background-size: 400%;
  color: #fff;
  border: none;
  cursor: pointer;
}

.menu-btn:hover:before ,.booking-btn:hover:before  {
  transform: scaleX(1);
}
.menu-btn:before ,.booking-btn:before  {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    rgba(150, 93, 233, 1) 10.8%,
    rgba(99, 88, 238, 1) 94.3%
  );
  transition: all 0.475s;
}
.button-content {
  position: relative;
  z-index: 1;
}




