.footer {
    background-color: #211F4C;
    color: #E6D5CA;
    padding: 20px 0;
    font-family: "GanhTypeRegular", sans-serif;
    margin-top: auto;
  }


  .copyright{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 0.9em;
    width: 100%; 

  }

  .link{
    color: #E6D5CA;
    font-weight: bold; 
  }


  .footer .social-icons a {
    color:#E6D5CA;
    text-decoration: none;
    transition: color 0.3s;
}

.footer .social-icon {
  font-size: 1.25em;
}

.footer-divider {
  border-top: 2px solid black; /* Adjust color and thickness as needed */
  width: 100%;
  margin-top: 20px;
}

.link {
  color: #000;
  font-weight: bold;
}

.link-item {
  text-decoration: none;
  color: #000;
}

.link-item:hover {
  color: #000;
}


.contact-item {
  margin: 5px 0;
}
.link.header {
  font-weight: bold;
}


.social-icon {
  font-size: 1.5rem;
}

.footer-divider {
  margin: 20px 0;
  border-top: 1px solid #211F4C;
}

.copyright {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .social-icons {
    justify-content: center;
    margin-top: 10px;
  }
}