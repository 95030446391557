:root {
  --main-color: #b59c8a;
  --background-color: #e6d5ca;
  --text-color: #211f4c;
  --secondary-text-color: #5d4e43;
  --border-color: #8a6c5c;
  --hover-color: #d5c2b8;
  --accent-color: #7a9e91;
  --link-color: #89a4c1;
  --success-color: #4ca64c;
  --error-color: #c03d3a;
  --warning-color: #d8943d;
}

.booking-header {
  color: var(--text-color);
  font-size: 36px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.search-header {
  color: var(--secondary-text-color);
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.booking-container {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  padding: 30px;
  background-color: #e6d5ca;
  font-family: "GanhTypeRegular", sans-serif;
}

.booking-form {
  margin-bottom: 40px;
}

.booking-form .form-label {
  font-weight: bold;
  color: var(--text-color);
}

.booking-form .form-control {
  border-radius: 10px;
  border: 1px solid var(--border-color);
  padding: 10px;
}

.booking-button {
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--background-color);
}

.booking-button:hover {
  background-color: var(--hover-color);
  border-color: var(--border-color);
  color: var(--text-color);
}

.search-section {
  margin-top: 50px;
}

.search-form .form-label {
  font-weight: bold;
  color: var(--text-color);
}

.search-form .form-control {
  width: 100%;
  /* max-width: 500px;
  padding: 10px;*/
  border-radius: 10px;
  border: 1px solid var(--border-color);
}

.search-button {
  background-color: var(--link-color);
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  color: var(--background-color);
}

.search-button:hover {
  background-color: var(--hover-color);
  color: var(--text-color);
}

.table-container {
  margin-top: 40px;
}

.booking-table {
  background-color: var(--background-color);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.booking-table th,
.booking-table td {
  vertical-align: middle;
  padding: 10px;
}

.booking-table th {
  background-color: var(--accent-color);
  color: var(--background-color);
  text-align: center;
}

.booking-table td {
  text-align: center;
}

.table-container .btn {
  margin: 0 5px;
}

.modal-content {
  border-radius: 15px;
  padding: 20px;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  font-size: 24px;
  color: var(--text-color);
}

.modal-footer .btn {
  border-radius: 10px;
}

.alert {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  border-radius: 10px;
  padding: 15px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.alert-success {
  background-color: var(--success-color);
  color: var(--background-color);
}

.alert-danger {
  background-color: var(--error-color);
  color: var(--background-color);
}

.alert-warning {
  background-color: var(--warning-color);
  color: var(--text-color);
}

.form-label {
  color: var(--text-color);
}

.btn-secondary {
  background-color: var(--accent-color);
  border-color: var(--link-color);
}

.btn-secondary:hover {
  background-color: var(--hover-color);
  border-color: var(--border-color);
  color: var(--text-color);
}
