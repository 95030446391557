.slider-container {
    width: 90%;
    margin: auto;
  }
  
  .slide {
    border-radius: 8px;
    padding: 20px;
    margin: 0 10px;
    text-align: center;
    transition: all 0.3s ease;
    height: 80%;
  }
  
  .slide:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .slide img {
    width: 100%; /* Ensure images fill their container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the entire area of the slide */
    border-radius: 10px; /* Optional: Add rounded corners to images */
  }
  
  /* Customize the dots */
  .slick-dots li button:before {
    font-size: 12px;
    color: #333;
  }
  
  .slick-dots li.slick-active button:before {
    color: #040e3a;
  }
  
  /* Customize the arrows */
  .slick-prev:before,
  .slick-next:before {
    font-size: 24px;
    color: #c5d6e9;
  }